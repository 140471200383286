import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/Layout';
import SEO from '../components/seo';
import MiniaturePost from '../components/MiniaturePost';
import Pagination from '../components/Pagination';
import * as styles from '../components/CoverWithTitle/CoverWithTitle.module.scss';
import { LangContext } from '../context';
import { translate } from '../translations';

const BlogList = ({
  data: {
    blogPosts: { edges: blogPosts },
  },
  pageContext: { currentPage, numPages, lang },
}) => {
  return (
    <LangContext.Provider value={lang}>
      <Layout>
        <SEO title={translate(lang, 'menu.homePage')} />

        <h1 className={styles.title}>
          {translate(lang, 'menu.homePage')}
        </h1>

        {blogPosts.map(post => (
          <MiniaturePost key={post.node.id} post={post} />
        ))}
        <Pagination currentPage={currentPage} numPages={numPages} />
      </Layout>
    </LangContext.Provider>
  )
} 

export const blogListQuery = graphql`
  query blogListQuery($skip: Int!, $limit: Int!, $lang: String!) {
    blogPosts: allMarkdownRemark(
      sort: { fields: [frontmatter___date], order: DESC }
      limit: $limit
      skip: $skip
      filter: { frontmatter: { type: { eq: "blogPost" }, lang: { eq: $lang } } }
    ) {
      edges {
        node {
          id
          excerpt(pruneLength: 400)
          frontmatter {
            title
            slug
            featuredImage {
              childImageSharp {
                fluid(maxWidth: 800) {
                  ...GatsbyImageSharpFluid
                }
              }
            }
            date
          }
        }
      }
    }
  }
`

export default BlogList
