import React from 'react';
import { Link } from 'gatsby';
import { useLang } from '../../context';
import { translate, getUrlPrefix } from '../../translations';
import * as styles from './Pagination.module.scss';

const Pagination = ({ currentPage, numPages }) => {
  const lang = useLang();

  const urlPrefix = getUrlPrefix(lang);

  const prevPage = currentPage > 1 && (currentPage - 2)
  const nextPage = currentPage < numPages && currentPage

  return (
    <div className={styles.wrapper}>
      <div>
        {
          (prevPage || prevPage === 0)  && (
            <Link to={prevPage === 0 ? `${urlPrefix}/` : `${urlPrefix}/page/${prevPage}`} className={styles.link}>
              &larr; {translate(lang, 'pagination.prev')}
            </Link>
          )
        }
      </div>
      <div>
        {
          nextPage && (
            <Link to={`${urlPrefix}/page/${nextPage}`} className={styles.link}>
              {translate(lang, 'pagination.next')} &rarr;
            </Link>
          )
        }
      </div>     
    </div>
  );
};

export default Pagination;
