import React from 'react';
import { Link } from 'gatsby';
import { useLang } from '../../context';
import { translate, getUrlPrefix } from '../../translations';
import CoverWithTitle from '../CoverWithTitle';
import PublishedOn from '../PublishedOn';
import * as styles from './MiniaturePost.module.scss';

const MiniaturePost = ({
  post: {
    node: {
      frontmatter: { title, slug, featuredImage, date },
      id,
      excerpt,
    },
  },
}) => {
  const lang = useLang();

  const articleUrl = `${getUrlPrefix(lang)}${slug}`;

  return (
    <div key={id} className={styles.post}>
      <Link to={articleUrl}>
        <CoverWithTitle title={title} featuredImage={featuredImage} />
      </Link>
      <div className={`prose ${styles.clip}`}>{excerpt}</div>
      <div className={styles.end}>
        <Link to={articleUrl} className={styles.readMore}>
          {translate(lang, 'post.readArticle')}
        </Link>
        <div className={styles.line} />
        <div className={styles.publishedOn}>
          <PublishedOn date={date} />
        </div>
      </div>
    </div>
  );
};

export default MiniaturePost;
